button {
	padding: 0;
	border: none;
	font: inherit;
	color: inherit;
	background-color: transparent;
	cursor: pointer;
	transition: all 0.3s;
}
:root {
	--cooltipz-bg-color: rgba(0, 0, 0, 0.9) !important;
	--cooltipz-font-size: 1rem !important;
	--cooltipz-border-radius: 10px !important;
}
input[type="range"] {
	background: red;
	height: 7px;
}
input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none !important;
	background: blue;
	height: 10px;
	width: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input {
	border: none;
}
input[type="text"]:focus {
	border: none !important;
}
input[type="number"] {
	-moz-appearance: textfield;
}
p {
	margin: 0;
	padding: 0;
}
a {
	all: unset;
}
button:hover {
	box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
		rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

/*       ScrollBar        */

*::-webkit-scrollbar {
	width: 1px;
}

*::-webkit-scrollbar-track {
	border-radius: 8px;
	background-color: transparent;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #27a3d5;
}
/*FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER*/
footer {
	position: relative;
	display: flex;
	bottom: 0;
	height: 50px;
	width: 80%;
	z-index: 1;
	padding: 0 10%;
	background-color: rgb(13, 13, 13);
}
.footer_content {
	width: 100%;
	display: flex;
	align-items: center;
}
.safepad_copyright {
	color: #adb5bd;
}
.safepad_copyright > span {
	white-space: nowrap;
}
.footer_external_links {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
	align-items: center;
}
.footer_external_links > .link {
	text-align: center;
	white-space: nowrap;
	letter-spacing: 1px;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 0.8rem;
	color: #adb5bd;
	cursor: pointer;
	transition: color 0.3s;
}
.footer_external_links > .link:hover {
	color: #fff;
}
@media screen and (max-width: 650px) {
	footer .footer_external_links {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		gap: 0;
		row-gap: 5px;
	}
	footer .footer_external_links > .link {
		text-align: left;
	}
	.home_page__content_container {
		margin-bottom: 5rem;
	}
	footer {
		position: relative;
		height: 100px;
		padding: 1rem 1rem 0 1rem;
	}
	.footer_content {
		flex-direction: column;
		gap: 1rem;
	}
}

/*FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER*/

/*HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER*/
header {
	display: flex;
	justify-content: space-between;
	width: 80%;
	height: 94px;
	background-color: #0000000a;
	padding: 0 10% 0 10%;
}
.header-logo_container {
	cursor: pointer;
	height: 100%;
	width: auto;
	font-size: 1.6rem;
	font-weight: 400;
	justify-content: center;
	align-items: center;
	gap: 1.6rem;
	display: flex;
	user-select: none;
}
.header-connect-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.header-connect_btn {
/*	background: linear-gradient(90deg, #8736BC 19.85%, #578CD1 76.18%, #24e8e8 114.52%);*/
	background: #8736BC;
	padding: 1rem 0;
	width: 9rem;
	max-width: 9rem;
	border-radius: 10px;
}
.header-connect_btn:hover {
	background: #578CD1;
}
@media screen and (max-width: 390px) {
	header .header-logo_container {
		gap: 0.4rem;
	}
	header img {
		width: 3rem;
		height: 3rem;
	}
	.header-connect_btn {
		width: 7rem;
	}
}

/*HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER HEADER*/
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
	position: absolute;
	left: 11rem;
}
input[type="datetime-local"] {
	position: relative;
}

/*HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE*/
.home_page {
	margin: 5rem 0 5rem 0;
	display: flex;
	align-items: center;
}
.home_page__h {
	font-size: 3rem;
	color: #ffffff;
	font-weight: 400;
}
.home_page__info {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10rem;
	padding: 3rem 0;
}
.home_page__info-cell {
	font-family: Arial, Helvetica, sans-serif;
	width: auto;
	font-size: 1.4rem;
	white-space: nowrap;
}
.home_page__info-cell > span {
	color: grey;
	font-size: 1.2rem;
}
.home_page__info-cell > p {
	color: #fff;
	padding: 0;
	margin: 0;
	font-size: 1.5rem;
}
.home_page-logo_container {
	letter-spacing: 1px;
	height: 100%;

	width: auto;
	font-size: 1.4rem;
	text-transform: uppercase;
	font-weight: 400;
	justify-content: center;
	align-items: center;
	gap: 1.6rem;
	display: flex;
	user-select: none;
	max-width: 25rem;
}
.home_page__content_container {
	background: #160826;
	width: auto;
	min-height: 300px;
	border-radius: 20px;
	display: flex;
	max-width: 800px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
		rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.home_page__content_side {
	width: 100%;
	min-height: 300px;
	padding: 4rem;
}

.home_page__content__side_header {
	font-family: Arial, Helvetica, sans-serif;
	line-height: 22.4px;
	font-size: 1.1rem;
	background-color: #21103a;
	color: #ffffff;
	padding: 1rem 2rem 2rem 2rem;
	border-radius: 20px;

	letter-spacing: 1px;
	max-width: 100%;
}
.home_page__content__side_buttons {
	margin-top: 5rem;
	display: flex;
	max-width: 100%;
	align-items: center;
	border-radius: 20px;
	justify-content: space-around;
}

.home_page__content__side_buttons > a > button {
/*	background: linear-gradient(90deg, #8736BC 19.85%, #578CD1 76.18%, #24e8e8 114.52%);*/
	background: #578CD1;
	width: 100%;
	min-width: 14rem;
	padding: 1.5rem;
	border-radius: 10px;
	font-size: 1.2rem;
	white-space: nowrap;
}
.home_page__content__side_buttons > a > button:hover {
/*	background: linear-gradient(90deg, #578CD1 19.85%, #24e8e8 46.18%, #8736BC 114.52%);*/
	background: #8736BC;
}
/*HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE HOME-PAGE*/

/*PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE*/
.presale_page {
	z-index: 2;
	gap: 20px;
	margin: 5rem 0 10rem 0;
}
.presale_page-container {
	padding: 2.5rem;
	border-radius: 20px;
	min-height: 30px;
	display: flex;
	justify-content: start;
	align-items: center;
	max-width: 1600px;
}
.presale_page-container_information {
	background-color: #21103a;
}

.presale_page-container_information .side {
	width: 100%;
}
.presale_page-container_information .side > .description {
	color: rgba(124, 124, 124, 1);
	font-family: Arial, Helvetica, sans-serif;
	letter-spacing: 1px;
	word-wrap: break-word;
	line-height: 22.4px;
}
.presale_page-container_information .side > span {
	font-size: 48px;
	color: #fff;
}
.presale_page-container_information .side > p {
	margin: 1rem 0;
	font-size: 1.4rem;
	font-family: Arial, Helvetica, sans-serif;
}
.presale_page-container_warning {
	font-family: Arial, Helvetica, sans-serif;
	letter-spacing: 1px;
	line-height: 1.3rem;
	gap: 3rem;
	background-color: rgba(231, 84, 111, 0.36);
}
.presale_page-container_warning img {
	width: 3.4rem;
}
.presale_page-container_fetched {
	background-color: rgba(6, 38, 34, 0.5);
	gap: 3rem;
	font-family: Arial, Helvetica, sans-serif;
	letter-spacing: 1px;
}
.presale_page-container_fetched b {
	color: #ffffff;
}
.presale_page-container_fetched .item {
	padding-left: 3rem;
	border-left: 1px solid rgba(255, 255, 255, 0.36);
}
.presale_page-container_input input {
	border: none;
	height: 2rem;
	border-radius: 30px;
	color: #ffffff;
	padding: 0 0 0 1rem;
	width: 100%;
	margin: 0.6rem 0 0 0;
	background-color: #21103a;
}

.presale_page-container_input input:focus {
    color: #ffffff;
}

.presale_page-container_input input::placeholder {
  color: grey;
  opacity: 1; /* Firefox */
}

.presale_page-container_input
	input[type="datetime-local"]::-webkit-calendar-picker-indicator {
	width: 90%;
	height: 1.4rem;
	color: rgba(0, 0, 0, 0);
	justify-content: center;
	opacity: 1;
}
.presale_page-container_input {
	align-items: start;
	background-color: #160826;
	flex-direction: column;
	text-align: left;
}
.presale_page-container_input span {
	font-size: 2.7rem;
	margin: 1rem 0;
}
.presale_page-container_input p {
	letter-spacing: 1px;
	margin-top: 2rem;
	font-family: Arial, Helvetica, sans-serif;
}
.presale_page-container_input b {
	all: unset;
	color: #24e7e8;
}
.presale_page-confirm_button {
	padding: 1rem 3rem;
	background: linear-gradient(
		96.22deg,
		#88d09b 8.48%,
		#13a599 36.92%,
		#18a6bb 62.48%,
		#27a3d5 87.62%
	);
	border-radius: 10px;
	margin-left: 2rem;
}
.presale_page-confirm_button:disabled {
	background: rgba(30, 57, 57, 1);
	cursor: no-drop;
}
button:disabled {
	background: grey !important;
	cursor: no-drop;
}
.step_container {
	width: 0;
	height: 0;
	display: none;
	transition: width 0.3s;
}
.step_container img {
	width: 2rem;
}
.step_container.show {
	width: auto;
	height: auto;
	display: flex;
}
@media screen and (max-width: 490px) {
	.step_container > div > span {
		width: 100%;
		justify-content: space-between !important;
	}
	.step_container > div {
		padding: 0.5rem 0;
	}
	.step_container {
		padding: 1.2rem 0.4rem !important;
	}
}
.step_container > div {
	display: flex !important;
	font-size: 1rem !important;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin: 0;
}
.step_container > div > span {
	font-size: 1rem;
	height: auto;
	margin: 0.5rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem;
	border-radius: 1000px;
}
.step_container > div > span.checked {
	background-color: #39bb18;
	color: #fff !important;
}
.step_container > div > span.checked img {
	display: none;
}
@media screen and (max-width: 1730px) {
	.presale_page-container_information {
		flex-direction: column;
		padding: 1.7rem;
	}
}
@media screen and (max-width: 660px) {
	.presale_page-container {
	}
	.presale_page-container_fetched {
		gap: 1rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.presale_page-container_fetched > .item {
		text-align: center;
		padding: 0;
		border: none;
	}
}
@media screen and (max-width: 490px) {
	.presale_page-container_information > .side > span {
		font-size: 1.4rem;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
	.presale_page-container_information > .side > p {
		font-size: 1.2rem;
		width: 60%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
	.presale_page-container {
		padding: 1.2rem;
	}
	.presale_page-container input {
		width: 95%;
	}
	.presale_page-container_warning > img {
		display: none;
	}
	.presale_page-container_information {
		text-align: left;
		align-items: flex-start;
		justify-content: flex-start;
	}
}

/*PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE PRESALE-PAGE*/

/*PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE*/
.presales_page {
	z-index: 2;
	gap: 20px;
	margin: 2rem 0 10rem 0;
}
.presales_page-container_header {
	background-color: #21103a;
	font-size: 3rem;
	border-radius: 20px;
	padding: 3.4rem;
	color: #ffffff;
}
.presales_page-container_grid {
	display: grid;
	gap: 30px;
	width: 100%;
	overflow-y: scroll;
	min-height: 500px;
	max-height: 800px;
	padding: 1.2rem 0;
	grid-template-columns: repeat(3, 1fr);
}
.presales_page-grid_cell {
	background-color: #21103a;
	max-height: 400px;
	border-radius: 20px;
	padding: 2rem;
	letter-spacing: 1px;
}
.presales_page-grid_cell .start_time {
	width: 100%;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 0.8rem;
	letter-spacing: 0.7px;
	color: #ffffff;
}
.presales_page-grid_cell .links_n_supply {
	width: 100%;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 0.8rem;
	letter-spacing: 0.7px;
	color: #656565;
	display: flex;
	margin-bottom: 1rem;
}
.presales_page-grid_cell .links_n_supply .side {
	width: 50%;
	display: flex;
	height: auto;
	align-items: center;
	gap: 1rem;
}
.presales_page-grid_cell .links_n_supply .links {
	justify-content: flex-start;
	width: 35%;
}
.presales_page-grid_cell .links_n_supply .links img {
	cursor: pointer;
}
.links > img {
	width: 1.3rem;
}
.presales_page-grid_cell .links_n_supply .supply {
	justify-content: flex-end;
	width: 65%;
}
.supply > span {
	padding: 0.8rem;
	background-color: #282828;
	border-radius: 1000px;
	font-size: 1.3rem;
	color: #52a8ff;
	font-weight: 600;
}
.presales_page-grid_cell > .header_n_logo {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 2rem;
	margin-bottom: 2rem;
}
.presales_page-grid_cell > .header_n_logo > .header > span {
	font-size: 2rem;
}

.presales_page-grid_cell > .header_n_logo > img {
	width: 72px;
}

.presales_page-grid_cell > .status {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: Arial, Helvetica, sans-serif;
	color: #ffffff;
	font-size: 0.8rem;
	margin-top: 5rem;
}
.presales_page-grid_cell > .statusbar {
	margin: 0.5rem 0 1rem 0;
	overflow: hidden;
	position: relative;
	padding: 0.3rem 0;
	background: #160826;
	border-radius: 0 10px 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.presales_page-grid_cell > .statusbar > .progressbar {
	position: absolute;
	left: 0;
	border-left: 3px solid #52a8ff;
	width: 100%;
	height: 100px;
	background: linear-gradient(90deg, #8736bc 19.85%, #578cd1 76.18%, #24e8e8  114.52%);
	transition: all 0.7s;
}
.presales_page-grid_cell > .interact_buttons {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.presales_page-grid_cell > .interact_buttons > button {
	width: 40%;
	height: 4rem;
	border-radius: 10px;
	background: linear-gradient(90deg, #8736bc 19.85%, #578cd1 76.18%, #24e8e8 114.52%);
	font-size: 1.5rem;
}
.presales_page-grid_cell > .interact_buttons > button:disabled {
	background: rgba(30, 57, 57, 1);
	cursor: no-drop;
}
.presales_page-grid_cell > .statusbar > span {
	margin: 0 1rem;
	z-index: 1;
}
@media screen and (max-width: 1810px) {
	.presales_page-container_grid {
		grid-template-columns: repeat(2, 1fr);
	}
	.presales_page-container_grid > .presales_page-grid_cell > .links_n_supply {
		flex-direction: column;
		margin: 1rem 0;
	}
	.presales_page-container_grid
		> .presales_page-grid_cell
		> .links_n_supply
		> .side {
		width: 100%;
	}
}
@media screen and (max-width: 840px) {
	.presales_page-container_grid {
		grid-template-columns: repeat(1, 1fr);
	}
	.presales_page-container_header {
		text-align: center;
		justify-content: center;
		align-items: center;
		display: flex;
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@media screen and (max-width: 400px) {
	.presales_page {
		margin-bottom: 5rem;
	}
	.presales_page-grid_cell > .interact_buttons > button {
		width: 45%;
		height: 4rem;
		font-size: 1rem !important;
		border-radius: 10px;
		background: linear-gradient(
			96.22deg,
			#88d09b 8.48%,
			#13a599 36.92%,
			#18a6bb 62.48%,
			#27a3d5 87.62%
		);
		font-size: 1.5rem;
	}
}
/*PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE PRESALES-PAGE*/

/*LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE*/
.locker_page {
	z-index: 2;
	gap: 20px;
	margin: 4rem 0 10rem 0;
}
.locker_page-container {
	padding: 2.5rem;
	border-radius: 20px;
	min-height: 30px;
	display: flex;
	justify-content: start;
	align-items: center;
	max-width: 1600px;
}
.locker_page-header {
	justify-content: space-between;
	padding: 3.5rem 2.5rem;
	background-color: #21103a;
}
.locker_page-header > span {
	font-size: 3rem;
	color: white;
}
.locker_page-header > p {
	font-size: 2rem;
	color: #fff;
}
.locker_page-header > p > b {
	font-size: 2rem;
	color: #10b981;
}
.locker_page-form {
	gap: 3rem;
	flex-direction: column;
	align-items: flex-start;
	background-color: #21103a;
}
.locker_page-form .lock_amount,
.token_address,
.logo_link {
	display: flex;
	flex-direction: column;
}

.locker_page-form .lock_amount,
.token_address,
.logo_link,
.confirm,
.unlock_time {
	letter-spacing: 1px;
	font-family: Arial, Helvetica, sans-serif;
	display: flex;
	width: 80%;
	gap: 1rem;
}
.locker_page-form .lock_amount > .amount_buttons {
	display: flex;
	justify-content: center;
	gap: 3rem;
}
.locker_page-form .lock_amount > .amount_buttons > button {
    font-size: 16px;
    border-radius: 10px;
/*    transform: skew(-5deg);*/
    text-decoration: none;
    font-family: Rajdhani,sans-serif;
    color: #fff;
    padding-inline:1.25rem;line-height: 40px;
/*    transform-origin: right;*/
    transition: all .3s ease;
    background: linear-gradient(90deg, #8736bc 19.85%, #578cd1 76.18%, #24e8e8 114.52%);
/*    border: 1px solid transparent;*/
    font-weight: 600;
    min-width: 150px;
    text-align: center;
}
.locker_page-form .unlock_time {
	align-items: center;
}
.locker_page-form .unlock_time input[type="datetime-local"] {
	width: 12rem;
}
.locker_page-form input[type="text"] {
	width: 100%;
	color: #f1f1f1;
	line-height: 3rem;
	padding: 0 0 0 0.5rem;
	border-radius: 10px;
	background: rgba(255, 255, 255, 0.04);
}
.locker_page-form input[type="datetime-local"] {
	width: auto;
	color: #f1f1f1;
	line-height: 3rem;
	padding: 0 0 0 0.5rem;
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.04);
}
.locker_page-form .confirm {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.locker_page-form .confirm > button {
    font-size: 16px;
    border-radius: 10px;
/*    transform: skew(-5deg);*/
    text-decoration: none;
    font-family: Rajdhani,sans-serif;
    color: #fff;
    padding-inline:1.25rem;line-height: 40px;
/*    transform-origin: right;*/
    transition: all .3s ease;
    background: linear-gradient(90deg, #8736bc 19.85%, #578cd1 76.18%, #24e8e8 114.52%);
/*    border: 1px solid transparent;*/
    font-weight: 600;
    min-width: 150px;
    text-align: center;
}
@media screen and (max-width: 950px) {
	.locker_page-form .lock_amount,
	.token_address,
	.logo_link,
	.confirm,
	.unlock_time {
		letter-spacing: 1px;
		font-family: Arial, Helvetica, sans-serif;
		display: flex;
		width: 100%;
		gap: 1rem;
	}
	.locker_page-form .lock_amount > .amount_buttons {
		gap: 5px;
	}
	.locker_page-header {
		justify-content: space-between;
		flex-direction: column;
		padding: 2rem 00rem;
	}
	.locker_page-header > span {
		font-size: 2.5rem;
	}
}
@media screen and (max-width: 450px) {
	.locker_page-form .lock_amount > .amount_buttons {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	.locker_page-form {
		padding: 2.5rem 1rem;
	}
	.locker_page-form .unlock_time {
		justify-content: space-between;
		gap: 0;
	}
	.locker_page-header > span {
		font-size: 2.2rem;
	}
}

/*LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE LOCKER-PAGE*/

/*LOCKER-DASHBOARD LOCKER-DASHBOARD LOCKER-DASHBOARD LOCKER-DASHBOARD LOCKER-DASHBOARD LOCKER-DASHBOARD LOCKER-DASHBOARD*/

.lockerdashboard_page {
	z-index: 2;
	gap: 5rem;
	margin: 4rem 0 10rem 0;
}
.lockerdashboard_page-header {
	justify-content: space-between;
	padding: 3.5rem 2.5rem !important;
	background-color: #21103a;
}
.lockerdashboard_page-container {
	padding: 2.5rem;
	border-radius: 20px;
	min-height: 30px;
	display: flex;
	justify-content: start;
	align-items: center;
	max-width: 1600px;
}
.lockerdashboard_page-header > span {
	font-size: 3rem;
	color: #ffffff;
}
.lockerdashboard_page-info_wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.lockerdashboard_page-info_container {
	max-width: 600px;
	width: 100%;
	padding: 3rem;
	border-radius: 20px;
	min-height: 200px;
	background: rgba(255, 255, 255, 0.04);
}
.lockerdashboard_page-info_container > .timer {
	padding: 2rem 0;
	display: flex;
	gap: 1rem;
	color: rgba(16, 185, 129, 1);
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.lockerdashboard_page-info_container > .timer > p {
	font-size: 1.8rem;
	word-spacing: 1.4rem;
}
.lockerdashboard_page-info_container > .info_row {
	display: flex;
	width: 100%;
	padding: 1rem 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	justify-content: space-between;
}
.lockerdashboard_page-info_container > .info_row > p {
	color: rgba(16, 185, 129, 1);
}

@media screen and (max-width: 650px) {
	.lockerdashboard_page-info_container {
		padding: 2rem;
	}
}
@media screen and (max-width: 550px) {
	.lockerdashboard_page-info_container {
		padding: 2rem 0.5rem;
	}
	#adr {
		overflow: hidden;
		width: auto;
	}
}
/*LOCKER-DASHBOARD LOCKER-DASHBOARD LOCKER-DASHBOARD LOCKER-DASHBOARD LOCKER-DASHBOARD LOCKER-DASHBOARD LOCKER-DASHBOARD*/

@media screen and (max-width: 950px) {
	.home_page__content__side_buttons {
		flex-direction: column;
		gap: 1rem;
		margin-top: 1rem;
		align-items: flex-start;
	}
	.home_page__content__side_buttons > a {
		width: 100%;
	}
	footer,
	header,
	.page {
		width: 95% !important;
	}

	.home_page__content_container {
		max-width: unset;
	}
	.home_page {
		padding: 0;
	}
	.home_page__info {
		flex-direction: column-reverse;
		gap: 2rem;
		align-items: flex-start;
	}
	.home_page__content__side_buttons > a > button {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.home_page__content_side {
		padding: 1rem;
	}
	.home_page__content__side_header {
		padding: 1rem;
	}
}

/*NON-COMPONENT-UI NON-COMPONENT-UI NON-COMPONENT-UI NON-COMPONENT-UI NON-COMPONENT-UI NON-COMPONENT-UI*/
.page {
	z-index: 1;
	width: 80%;
	opacity: 0;
	padding: 0 10%;
	display: flex;
	flex-grow: 2;
	flex-wrap: wrap;
	flex-direction: column;
	position: relative;
	text-align: left;
	transition: opacity 0.3s, transform 0.3s;
	transform: translateY(100px);
}
.SHOW {
	opacity: 1;
	transform: translateY(0);
}
.background_image {
	position: absolute;
	width: 100%;
	top: 20%;
	z-index: 0;
}
body {
	background: rgba(15, 15, 15, 1);
	color: #fff;
}
.background_image > img {
	user-select: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
.app_content {
	overflow: hidden;
	position: relative;
	background: linear-gradient(181.72deg, #0C0018 19.85%, #210F39 76.18%, #232A40 114.52%);;
	text-align: center;
	min-height: 100dvh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.contribute_layer {
	position: fixed;
	top: 0;
	z-index: -1;
	opacity: 0;
	width: 100dvw;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100dvh;
}
.contribute_layer.show {
	z-index: 100;
	opacity: 1;
}
.contribute_container {
	width: 330px;
	height: auto;
	overflow-y: scroll;
	z-index: 1;
	display: flex;
	flex-direction: column;
	gap: 40px;
	background-color: #21103a;
	padding: 3rem 1rem;
	border-radius: 20px;
}
.contribute_balance {
	padding: 0;
	border-radius: 10px;
	background-color: #f1f1f1;
	color: #160826;
	max-width: 100%;
	padding: 0 0 0 1rem;
	align-items: center;
	display: flex;
	justify-content: space-between;
}
.contribute_balance > span {
	font-size: 1.2rem;

	font-family: Arial, Helvetica, sans-serif;
}
.contribute_balance > .balance {
	padding: 1rem 0;
	font-size: 1.2rem;
	border-radius: 0 10px 10px 0;
	background: linear-gradient(90deg, #8736bc 19.85%, #578cd1 76.18%, #24e8e8 114.52%);
	max-width: 7rem;
	min-width: 7rem;

	color: #fff;
}
.contibute_amount {
	padding: 0;
	border-radius: 10px;
	background-color: #f1f1f1;
	color: #000;
	max-width: 100%;
	padding: 0 0 0 0;
	align-items: center;
	display: flex;
	justify-content: space-between;
}
.contibute_amount > .contribute_amount-input {
	all: unset;
	font-family: Arial, Helvetica, sans-serif;
	background: none;
	text-align: left;
	border: none;
	max-width: 50%;
	margin: 0 0 0 1rem;
	padding: 1rem 0;
	font-size: 1.2rem;
}
.contibute_amount > .max_amount {
	min-width: 7rem;
	max-width: 7rem;
	padding: 1rem 0;
	font-size: 1.2rem;
	border-radius: 0 10px 10px 0;
	background: linear-gradient(90deg, #8736bc 19.85%, #578cd1 76.18%, #24e8e8 114.52%);
	color: #fff;
}
.contribute_buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3rem;
}

.contribute_button-confirm {
	font-size: 1.2rem;
	border-radius: 10px;
	padding: 1rem 2rem;
	background: linear-gradient(90deg, #8736bc 19.85%, #578cd1 76.18%, #24e8e8 114.52%);
	max-width: 200px;
}
.lockerdashboard_page-info_container .header {
	margin-bottom: 4rem;
	font-size: 1.4rem;
}
.info_row button {
	background: linear-gradient(
		96.22deg,
		#88d09b 8.48%,
		#13a599 36.92%,
		#18a6bb 62.48%,
		#27a3d5 87.62%
	);
	padding: 1rem 0;
	width: 9rem;
	max-width: 9rem;
	border-radius: 1000px;
}
.contribute_button-close {
	font-family: Arial, Helvetica, sans-serif;
	text-decoration-line: underline;
	color: #939393;
}
.contribute_closer {
	position: absolute;
	width: 100dvw;
	height: 100dvh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 0;
}
@font-face {
	font-family: duppio;
	src: url("./media/fonts/DoppioOne-Regular.ttf");
}
/*NON-COMPONENT-UI NON-COMPONENT-UI NON-COMPONENT-UI NON-COMPONENT-UI NON-COMPONENT-UI NON-COMPONENT-UI*/

/* ELROS IMPROVEMENTS */

.default-btn {
    font-size: 16px;
    border-radius: 10px;
/*    transform: skew(-5deg);*/
    text-decoration: none;
    font-family: Rajdhani,sans-serif;
    color: #fff;
    padding-inline:1.25rem;line-height: 40px;
/*    transform-origin: right;*/
    transition: all .3s ease;
    background: linear-gradient(90deg, #8736bc 19.85%, #578cd1 76.18%, #24e8e8 114.52%);
/*    border: 1px solid transparent;*/
    font-weight: 600;
    min-width: 150px;
    text-align: center;
}

.default-btn:hover {
    color: #fff;
/*    transform: translateY(-3px);*/
	border: 1px solid #8736bc;
    background: transparent;
    box-shadow: 0 0 10px #8736bc87;
    cursor: pointer;
    border-radius: 10px;
}

button:disabled {
    background: linear-gradient(90deg, #a0a0a0 19.85%, #cecece 76.18%, #a0a0a0 114.52%) !important;
    cursor: not-allowed !important;
}

@media(min-width: 768px) {
    .default-btn {
        padding-inline:2rem;
        line-height: 56px;
        font-size: 16px;
    }
}

.default-btn span {
    position: inherit;
    z-index: 1;
    text-transform: capitalize;
    font-weight: inherit
}

.default-btn--small {
    line-height: 40px;
    padding: 0 1.2rem
}

.default-btn--secondary {
    background-color: transparent;
    border-color: #28dbd1;
    color: #28dbd1
}

.default-btn--rounded {
    border-radius: 99px
}

.default-btn.active {
    background: #28dbd1
}

.text-btn {
    display: inline-block;
    color: #fff;
    font-weight: 700
}

@media(min-width: 992px) {
    .text-btn {
        font-size:1.25rem
    }
}

.text-btn svg {
    transition: all .3s ease;
    vertical-align: middle
}

.text-btn:hover {
    color: #28dbd1
}

.text-btn:hover svg {
    transform: translateX(5px)
}
